import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './HorizontalSlider.module.scss';

export const HorizontalSlider = ({ images }: { images: string[]}) => {

    const scrollRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const scrollToIndex = (index: number) => {
        if (index === 0 && selectedIndex === images.length - 1) {
            scrollToIndex(images.length);
        } else if (index === images.length - 1 && selectedIndex === 0) {
            scrollToIndex(-1);
        } else {
            const target = scrollRef.current!;
            const scrollLeft = index * target.clientWidth + target.clientWidth;
            target.scrollLeft = scrollLeft;
        }
        intervalRef.current != null && clearInterval(intervalRef.current);
    }

    useEffect(() => {
        const refDiv = scrollRef.current;
        if (!refDiv) {
            return;
        }

        intervalRef.current = setInterval(() => {
            if (scrollRef.current && !scrollRef.current.matches(":hover")) {
                scrollRef.current.scrollLeft += scrollRef.current.clientWidth;
            }
        }, 5000);

        const updateIndex = (target: HTMLDivElement) => {
            const scrollWithoutFirst = target.scrollLeft - target.clientWidth;
            const index = Math.round(scrollWithoutFirst / target.clientWidth);
            setSelectedIndex((index + images.length) % images.length);
        };
        const wrapAround = (target: HTMLDivElement) => {
            const scrollWithoutFirst = target.scrollLeft - target.clientWidth;
            const fractionalIndex = scrollWithoutFirst / target.clientWidth;

            target.style.scrollBehavior = 'auto';
            if (fractionalIndex < -0.5) {
                target.scrollLeft += target.scrollWidth - target.clientWidth * 2;
            } else if (fractionalIndex > images.length - 0.5) {
                target.scrollLeft -= target.scrollWidth - target.clientWidth * 2;
            }
            target.style.scrollBehavior = 'smooth';
        };
        const fnScroll = (ev: Event) => {
            const target = ev.currentTarget as HTMLDivElement;
            updateIndex(target);
        }
        const fnScrollEnd = (ev: Event) => {
            const target = ev.currentTarget as HTMLDivElement;
            updateIndex(target);
            wrapAround(target);
        }
        
        refDiv.style.scrollBehavior = 'auto';
        refDiv.scrollLeft = refDiv.clientWidth;
        refDiv.style.scrollBehavior = 'smooth';

        refDiv.addEventListener('scroll', fnScroll, { passive: true });
        refDiv.addEventListener('scrollend', fnScrollEnd, { passive: true });
        return () => {
            refDiv.removeEventListener('scroll', fnScroll);
            refDiv.removeEventListener('scrollend', fnScrollEnd);
            intervalRef.current != null && clearInterval(intervalRef.current);
        }
    }, []);

    return <div className={styles.carousel}>
        <div className={styles.hiddenContainer} ref={scrollRef}>
            <img className={styles.carouselImage} src={images[images.length - 1]} />
            {images.map(image => (
                <img className={styles.carouselImage} src={image} key={image} />
            ))}
            <img className={styles.carouselImage} src={images[0]} />
        </div>
        <button className={styles.leftButton} onClick={scrollToIndex.bind(null, selectedIndex - 1)} />
        <button className={styles.rightButton} onClick={scrollToIndex.bind(null, selectedIndex + 1)} />
        <div className={styles.bottomButtons}>
            {images.map((image, index) => (
                <button
                    className={cn(styles.bottomButton, (selectedIndex === index) && styles.active)}
                    onClick={scrollToIndex.bind(null, index)}
                    key={image} />
            ))}
        </div>
    </div>
}