// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xmWWC5zN4Ohnk6ZuaROF{width:100%;height:100%;position:relative}.xmWWC5zN4Ohnk6ZuaROF .mX4uG4ORXFlm91DxEmNX{display:flex;flex-direction:row;width:100%;height:100%;overflow-x:auto;scroll-snap-type:x mandatory;scroll-behavior:smooth;-ms-overflow-style:none;scrollbar-width:none}.xmWWC5zN4Ohnk6ZuaROF .mX4uG4ORXFlm91DxEmNX:-webkit-scrollbar{display:none}.xmWWC5zN4Ohnk6ZuaROF .mX4uG4ORXFlm91DxEmNX .U_CovohVnF1oElo3gBtj{width:100%;height:100%;max-height:576px;object-fit:contain;flex:0 0 100%;scroll-snap-align:start}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX{position:absolute;bottom:32px;left:50%;height:24px;z-index:9;transform:translateX(-50%)}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX .fQybbcLWvOZ661nACXDp{width:12px;height:12px;margin:0 8px 0 8px;border-radius:50%;background-color:#9ca3af;cursor:pointer}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX .fQybbcLWvOZ661nACXDp.JToozD_dwkLyuL8HAalZ{background-color:#fff}.xmWWC5zN4Ohnk6ZuaROF .U73hnpYy5uwL9QKbK4GY,.xmWWC5zN4Ohnk6ZuaROF .F3397_OvRUC76reTi3qG{position:absolute;top:50%;width:48px;height:48px;border-radius:50%;background-color:#434b4f;cursor:pointer;transform:translateY(-50%);background-position:center}.xmWWC5zN4Ohnk6ZuaROF .F3397_OvRUC76reTi3qG{left:32px;display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Next-cloud.svg\");transform:translateY(-50%) rotate(180deg)}.xmWWC5zN4Ohnk6ZuaROF .U73hnpYy5uwL9QKbK4GY{right:32px;display:inline-block;background-repeat:no-repeat;content:\"\";background-image:url(\"../assets/icons/Next-cloud.svg\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": "xmWWC5zN4Ohnk6ZuaROF",
	"hiddenContainer": "mX4uG4ORXFlm91DxEmNX",
	"carouselImage": "U_CovohVnF1oElo3gBtj",
	"bottomButtons": "i_c2fUFxDns3uhGywbQX",
	"bottomButton": "fQybbcLWvOZ661nACXDp",
	"active": "JToozD_dwkLyuL8HAalZ",
	"rightButton": "U73hnpYy5uwL9QKbK4GY",
	"leftButton": "F3397_OvRUC76reTi3qG"
};
export default ___CSS_LOADER_EXPORT___;
