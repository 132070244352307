import styles from "./app.module.scss";
import { HorizontalSlider } from "ui";

function App() {
    const machinePdpData = window.app.preloadState.machinePdp;



    return <HorizontalSlider images={machinePdpData.images} />
}

export default App;
